import { Controller } from "stimulus";

import { decodeMails } from "../../util/mailsHelper";

export default class extends Controller {
  static targets = ["textContainer"];

  connect() {
    decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
    if (this.hasTextContainerTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      if (this.anchorController) {
        this.anchorController?.checkForMultipleLinktags(this.textContainerTarget);
      }
    }
  }
}
