/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start();
// TODO: re-enable Turbolinks when compatibility with focus-visible polyfill is fixed
// require("turbolinks").start();

// Polyfills
import "@stimulus/polyfills";
import "intersection-observer";
import "mdn-polyfills/Array.prototype.forEach";
import "mdn-polyfills/NodeList.prototype.forEach";

import "base";
import "components";
import "view_components";
import "controllers/frontoffice";

import "stylesheets/application";
