import { Controller } from "stimulus";
export default class extends Controller {
  static targets = ["cards", "selectors", "monthsFilter", "districtsFilter"];

  initialize() {
    this.timeout = false;
    this.delay = 250;
  }

  connect() {
    const cards = Array.from(document.getElementsByClassName("card-container"));
    this.handleFilters(cards, "districts");
    this.handleFilters(cards, "months");
    this.handleNotSelectableFilter();

    this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
    this.anchorController?.checkForMultipleLinktags(this.cardsTarget);
  }

  handleFilters(cards, option) {
    this._displayAll();
    const filters = Array.from(document.getElementsByClassName(option + "-filters-link"));
    filters.forEach((filter) => {
      filter.onclick = (e) => {
        e.preventDefault();
        if (e.target.classList.contains("is-disabled")) return;
        window.history.pushState({}, filter.id, "#" + filter.id);
        filters.forEach((item) => item.classList.remove("is-selected"));
        filter.classList.add("is-selected");
        cards.forEach((card) => card.classList.add("is-hidden"));
        const cardsToDisplay = Array.from(document.getElementsByClassName(filter.id));
        cardsToDisplay.forEach((card) => card.classList.remove("is-hidden"));
      };
    });
  }

  handleNotSelectableFilter() {
    const cards = Array.from(document.getElementsByClassName("card-container"));
    function onlyUnique(value, index, self) {
      // delete duplicate in arrays. Is used below.
      return self.indexOf(value) === index;
    }

    const districtFiltersToEnable = [];
    const monthFiltersToEnable = [];
    cards.forEach((element) => {
      districtFiltersToEnable.push(element.classList[1]);
      monthFiltersToEnable.push(element.classList[2]);
    });

    districtFiltersToEnable.filter(onlyUnique).forEach((element) => {
      Array.from(document.getElementsByClassName(element))[0].classList.remove("is-disabled");
    });

    monthFiltersToEnable.filter(onlyUnique).forEach((element) => {
      Array.from(document.getElementsByClassName(element))[0].classList.remove("is-disabled");
    });
  }

  displayFilter(hash) {
    if (!hash) return;

    const filterElem = document.getElementById(hash);
    const filtersItemsElem = filterElem.closest(".filters-items");
    const uncheckedSelectors = this.selectorsTarget.querySelectorAll("input[type='radio']:not(:checked)");
    if (filtersItemsElem.classList.contains("is-hidden") || uncheckedSelectors.length > 1) {
      const toTarget = hash.includes("mois") ? this.monthsFilterTarget : this.districtsFilterTarget;
      toTarget.click();
    }

    filterElem.click();
  }

  selectType(e) {
    const selected = e.target.dataset.type;
    const unselected = selected === "months" ? "districts" : "months";
    this._clear();
    this._displayAll();

    this.element.querySelector(`.${selected}-filters`).classList.remove("is-hidden");
    this.element.querySelector(`.${unselected}-filters`).classList.add("is-hidden");
  }

  _clear() {
    const filters = this.element.querySelectorAll(".filters-link");
    [...filters].forEach((filter) => {
      filter.classList.remove("is-selected"); // onclick, clear all previously selected month or district filters
    });
  }

  _displayAll() {
    this.cardsTarget.childNodes.forEach((children) => {
      if (children.childNodes.length > 0) children.classList.remove("is-hidden");
    });
  }
}
